const IS_DEV = process.env.NODE_ENV !== "development" ? false : false; // true : backup, false: production

export const lang = "cn_18sky"; // en (English), fr (French), cn (China), cn_18sky(china 2)

const apiBase = () => {
  if (lang === "fr") return "https://api.comicverse.art/manga-graphql";
  if (lang === "cn") return "https://api.chaoremanhua.com/manga-graphql";
  if (lang === "cn_18sky") return "https://api.rehanman.com/manga-graphql";
  return "https://api.xtoon.xyz/manga-graphql";
};
export const API_URL_BASE = IS_DEV
  ? "http://localhost:6005/manga-graphql"
  : apiBase();

const cdnImages = () => {
  if (lang === "fr")
    return "https://img.comicverse.art/wp-content/uploads/WP-manga/data";
  if (lang === "cn") return "https://api.chaoremanhua.com/uploads/data";
  if (lang === "cn_18sky")
    return "https://api.rehanman.com/uploads/data/china18sky";
  return "https://cdn.xtoon.xyz/wp-content/uploads/WP-manga";
};

export const URL_IMAGES = cdnImages();

export const ENCODE_SECRET_KEY =
  process.env?.ENCODE_SECRET_KEY || "QiLCJhbGciOiJSUzI";

export const URL_UPLOAD = IS_DEV
  ? "http://localhost:6005/uploads"
  : // : 'https://api.xtoon.xyz/uploads';
    "https://api.comicverse.art/uploads";

export const URL_API_FOOTBALL = "https://v3.football.api-sports.io";
export const TOKEN_API_FOOTBALL = "54883420494ac499b7788e8dbcb84356";

export const pages = [
  {
    title: "Home",
    page: "home",
    edit: true,
  },
  {
    title: "Weekly",
    page: "weekly",
  },
  {
    title: "Genres",
    page: "genres",
  },
  {
    title: "New",
    page: "new",
  },
  {
    title: "My Library",
    page: "my-library",
  },
  {
    title: "Account setting",
    page: "account-setting",
  },
  {
    title: "Pricing",
    page: "pricing",
  },
];

export const genresLinks = [
  {
    title: "All",
    value: "all",
  },
  // {
  //   title: "Complete",
  //   value: "completed"
  // },
  {
    title: "Drama",
    value: "drama",
  },
  {
    title: "Romance",
    value: "romance",
  },
  {
    title: "BL",
    value: "bl",
  },
  {
    title: "Fantasy",
    value: "fantasy",
  },
  {
    title: "Action",
    value: "action",
  },
  {
    title: "GL",
    value: "gl",
  },
  {
    title: "Comedy",
    value: "comedy",
  },
  {
    title: "Horror",
    value: "horror",
  },
  {
    title: "Thriller",
    value: "thriller",
  },
  {
    title: "Historical",
    value: "historical",
  },
  {
    title: "School life",
    value: "school-life",
  },
  {
    title: "Sci-fi",
    value: "sci-fi",
  },
];
